import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Login from './Login';
import Signup from './Signup';
import HomeAdmin from './HomeAdmin';
import HomeUser from './HomeUser';

function App() {
    const toastRef = React.useRef(null);

    return (
        <Router>
            <div className="App">
                <Toast ref={toastRef} />
                <Routes>
                    <Route path="/" element={<Login toast={toastRef} />} />
                    <Route path="/signup" element={<Signup toast={toastRef} />} />
                    <Route path="/homeAdmin" element={<HomeAdmin />} />
                    <Route path="/homeUser" element={<HomeUser />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const Login = ({ toast }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        
        return Object.keys(tempErrors).every(key => !tempErrors[key]);
    };

    const handleLogin = async () => {
        if (validate()) {
            try {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/login`, {
                    params: {
                        username: username.trim(),
                        password: password.trim(),
                    }
                });
                
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Giriş başarılı.', life: 3000 });
                    await axios.post(`${process.env.REACT_APP_API_URL}/oturumKaydet`, { id: response.data.user.id, oturumId: response.data.user.oturumId });
                    if (response.data.user.isAdmin === 1) {
                        navigate('/homeAdmin', { state: { user: response.data.user } });
                    } else {
                        navigate('/homeUser', { state: { user: response.data.user } });
                    }
                }
            } catch (error) {
                if (error.response) {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data || 'Giriş sırasında bir hata oluştu.', life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Giriş sırasında bir hata oluştu.', life: 3000 });
                }
                setLoading(false)
            }
        }
    };
    
    

    // Inline styles for the profile icon container
    const profileIconStyle = {
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#007bff',
        width: '110px',
        height: '110px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        border: '4px solid white'
    };

    // Inline styles for the profile icon
    const profileIcon = {
        fontSize: '3rem',
        color: 'white'
    };

    return (
        <div className="login-container">
            <div className="login-card" style={{ position: 'relative', padding: '2rem', width: '100%', maxWidth: '400px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <ProgressSpinner />
                    </div>
                ) : (
                    <>
                        <div style={profileIconStyle}>
                            <i className="pi pi-user" style={profileIcon}></i>
                        </div>
                        <h2 style={{ marginTop: '4rem', marginBottom: '2rem', color: '#343a40' }}>Giriş</h2>
                        <div className="p-field login-field" style={{ marginBottom: '1rem', textAlign: 'left' }}>
                            <label htmlFor="username">Kullanıcı Adı</label>
                            <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)} maxLength={20} style={{ width: '100%', borderRadius: '6px', border: '1px solid #ced4da', padding: '0.75rem', fontSize: '1rem' }} />
                            {errors.username && <small className="p-error" style={{ color: '#e74c3c', fontSize: '0.85em', marginTop: '0.5rem', display: 'block' }}>{errors.username}</small>}
                        </div>
                        <div className="p-field login-field" style={{ marginBottom: '1rem', textAlign: 'left' }}>
                            <label htmlFor="password">Şifre</label>
                            <InputText id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={20} style={{ width: '100%', borderRadius: '6px', border: '1px solid #ced4da', padding: '0.75rem', fontSize: '1rem' }} />
                            {errors.password && <small className="p-error" style={{ color: '#e74c3c', fontSize: '0.85em', marginTop: '0.5rem', display: 'block' }}>{errors.password}</small>}
                        </div>
                        <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                            <Button label="Giriş Yap" icon="pi pi-sign-in" onClick={handleLogin} style={{ width: '48%', padding: '0.75rem', fontSize: '1rem', borderRadius: '6px', transition: 'background-color 0.3s ease, box-shadow 0.3s ease' }} />
                            <Button label="Kayıt Ol" className="p-button-secondary" onClick={() => navigate('/signup')} style={{ width: '48%', padding: '0.75rem', fontSize: '1rem', borderRadius: '6px', transition: 'background-color 0.3s ease, box-shadow 0.3s ease', backgroundColor: '#6c757d', borderColor: '#6c757d' }} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;

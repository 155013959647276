import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import styles from './HomeUser.module.css';
import { Tag } from 'primereact/tag'; // PrimeReact Tag bileşeni durum ikonları için
import { format } from 'date-fns';


const HomeUser = () => {
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [requests, setRequests] = useState([]);
    const [masaTipleri, setMasaTipleri] = useState([]);
    const [requestData, setRequestData] = useState({
        tipId: null,
        sinir: '',
        aciklama: '',
        userId: null, // User ID'yi buradan almak yerine useLocation'dan alacağız
    });
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const user = location.state?.user;

    const statusIcons = (status) => {
        switch (status) {
            case 0:
                return <Tag value="Beklemede" severity="warning" />;
            case 1:
                return <Tag value="Açık" severity="success" />;
            case 2:
                return <Tag value="Ret" severity="danger" />;
            case 3:
                return <Tag value="İptal" severity="danger" />;
            case 4:
                return <Tag value="Tamamlandı" severity="secondary" />;
            default:
                return '';
        }
    };

    const fetchTables = async (isFirstLoading) => {
        await fetchUsers();
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tables`);
            setTables(response.data);
        } catch (error) {
            showError(error.response.data);
        } finally {
        }
    };
    

    const fetchMasaTipleri = async (isFirstLoading) => {
        if(isFirstLoading !== false){
            setLoading(true);
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/masaTipleri`); // API URL'sini güncelleyin
            const masaTipleriFormatted = response.data.map(masaTipi => ({
                label: masaTipi.tanim, // 'tanım' label'a atanıyor
                value: masaTipi.id      // 'id' value'ya atanıyor
            }));
            setMasaTipleri(masaTipleriFormatted)
        } catch (error) {
            showError('Masa tiplerini çekerken hata');
        }
    };

    const fetchUsers = async (isFirstLoading) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`); // API URL'sini güncelleyin
            const oturumdanAt = response.data.filter((item) => {return item.id === user.id && item.oturumId !== user.oturumId})
            if (oturumdanAt.length > 0){
                showError('Başka bir oturum açıldı, bu oturum kapatılacaktır.')
                setTimeout(() => {
                    handleLogout();
                }, 1500);
            }
        } catch (error) {
            showError('Kullanıcıları çekerken hata');
        } finally {
        }
    };
    

    const fetchRequests = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/istekler/${userId}`);
            setRequests(response.data);
        } catch (error) {
            showError(error);
        } finally {
        }
    };

    const fetchData = async () => {
        setLoading(true)
        await fetchTables();
        await fetchMasaTipleri();
        if (user && user.id) {
            await fetchRequests(user.id);
        }
        setLoading(false)
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy'); // Tarih formatlama
    };
   
    useEffect(() => {
        if (user) {
            setRequestData(prevData => ({ ...prevData, userId: user.id }));
        }
    
    }, [user]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchTables(false); // İlk yükleme değil, düzenli güncelleme için false geçiyoruz
        }, 60000); // 60000 milisaniye = 1 dakika
        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        navigate('/');
    };

    const handleRequest = () => {
        fetchUsers();
        setDisplayDialog(true); // Popup'ı göster
    };

    // const showSuccess = (message) => {
    //     toast.current.show({ severity: 'success', summary: 'Başarılı', detail: message });
    // };

    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: message });
    };

    const handleSendRequest = async () => {
        let tempErrors = {};
        tempErrors.tipId = requestData.tipId ? "" : "Tip zorunludur.";
        tempErrors.aciklama = requestData.aciklama ? "" : "Açıklama zorunludur.";
        setErrors(tempErrors);
        if (Object.keys(tempErrors).every(key => !tempErrors[key])) {
            try {
                setLoading(true)
                setDisplayDialog(false)
                // Kullanıcı onay durumunu kontrol et
                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/kullaniciDurumKontrol/${requestData.userId}`);
                const userApproved = userResponse.data.onayDurum; // Onay durumu verisini al

                if (userApproved === 1) { //Kullanıcı hala onaylı ise gönderebilir.

                    const requestResponse = await axios.get(`${process.env.REACT_APP_API_URL}/istekKontrol/${requestData.userId}`); // API URL'sini güncelleyin
                    const istekCounter = requestResponse.data.istekCounter; // Onay durumu verisini al

                    if (istekCounter === 0){ // kullanıcının halihazırda masa isteği yoksa gönderebilir.
                        requestData.sinir = requestData.sinir ? requestData.sinir : 0.00;
                        await axios.post(`${process.env.REACT_APP_API_URL}/istekGonder`, requestData); // API URL'sini güncelleyin
                        //showSuccess('Gönderim başarılı!'); // Başarı mesajı
                        fetchData();
                    } else {
                        showError('Zaten halihazırda bekleyen veya açık durumda olan bir masa talebiniz var.');
                        setDisplayDialog(false); // Popup'ı kapat
                    }

                    // Formu ve hata mesajlarını sıfırla
                    setRequestData({
                        tipId: null,
                        sinir: '',
                        aciklama: '',
                        userId: location.state?.user.id,
                    });
                    setErrors({});
                    setDisplayDialog(false); // Popup'ı kapat
                } else {
                    // Hata mesajı göster ve kullanıcıyı login sayfasına yönlendir
                    showError('Kullanıcınız istek gönderebilecek durumda değil.');
                    navigate('/'); // Login sayfasına yönlendir
                }
            } catch (error) {
                showError('İstek gönderilirken bir hata oluştu.');
            }
        }
    };

    const cancelButtonTemplate = (rowData) => {
        if (rowData.onayDurum === 0) {
            return (
                <Button
                label="İptal Et"
                className={`p-button-danger ${styles.cancelButton}`}    
                    onClick={() => handleCancelRequest(rowData.id)}
                />
            );
        } else {
            return "";
        }
    };

    const handleCancel = () => {
        // Formu ve hata mesajlarını sıfırla
        setRequestData({
            tipId: null,
            sinir: '',
            aciklama: '',
        });
        setErrors({});
        setDisplayDialog(false); // Popup'ı kapat
    };

    const handleCancelRequest  = async (requestId) => {
        try {
            if (user && user.id) {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/istekler/${user.id}`);
                const istekler = response.data.filter((item) => {
                    return item.onayDurum === 0;
                })
                if (istekler.length > 0){
                    await axios.post(`${process.env.REACT_APP_API_URL}/istekIptal`, { istekId: requestId });
                    //showSuccess('İptal işlemi başarılı!'); // Başarı mesajı
                    fetchData();
                }else{
                    showError('İsteğiniz iptal edilemedi. Onaylanmış olabilir, yönetici ile iletişime geçiniz.');
                }
            }
        } catch (error) {
            showError(error);
        } finally {
        }
    };


    const renderDescription = (rowData) => {
        return (
            <div className={styles.ellipsis} title={rowData.aciklama}>
                {rowData.aciklama}
            </div>
        );
    };
    const rightContents = (
        <React.Fragment>
            <Button label="İstek" icon="pi pi-plus" onClick={handleRequest} className={`${styles.button} p-button-success p-button-outlined`} />
            <Button icon="pi pi-refresh" onClick={fetchData} label="Yenile" className={`${styles.button} p-button-outlined`} />
            <Button label="Çıkış" icon="pi pi-sign-out" onClick={handleLogout} className={`${styles.button} p-button-danger p-button-outlined`} />
        </React.Fragment>
    );

    const getTipLabel = (tipId) => {
        const option = masaTipleri.find(opt => opt.value === tipId);
        return option ? option.label : '';
    };

    const emptyCheck = (rowData, field) => {
        if ((field === 'kalanDk' && parseInt(rowData.kalanDk) === 0) || (field === 'sinir' && parseFloat(rowData.sinir) === 0.00) || (field === 'dahilEdilmeyenDk' && parseFloat(rowData.dahilEdilmeyenDk) === 0.00)){
            return ''
        }

        if (field === 'gecenDk' && rowData.gecenDk < 0){
            return 0
        }

        if (field === 'acilisTarihi' && rowData.tipId !== 0){
            let acilisDate = new Date(rowData.acilisTarihi);
            acilisDate.setHours(acilisDate.getHours() - 3);
            const acilisTarihi = acilisDate ? acilisDate.toLocaleString('tr-TR', {
                hour: '2-digit',
                minute: '2-digit'
            }) : ''
            return acilisTarihi
        }

        return rowData.tipId || rowData.tipId !== 0 ? 
            (field === 'ucret' || field === 'sinir' ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(rowData[field]) 
                : rowData[field]) 
                    : '';
    };


    return (
        <div className={styles.homeUser}>
            <Toast ref={toast} />
            <div className={styles.card}>
                <Toolbar end={rightContents} className="p-mb-4" />
                <TabView>
                    <TabPanel header="Masalar">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable value={tables} className={styles.dataTable} emptyMessage = 'Kayıt bulunamadı.' rowClassName={(rowData) => {
                                const sinir = parseFloat(rowData.sinir)
                                const ucret = parseFloat(rowData.ucret)
                                const detayUcret = parseFloat(rowData.detayUcret)
                                const finalUcret = ucret - detayUcret;
                                let rowStyle = ''
                                if ((sinir !== 0.00) && (finalUcret >= sinir) && rowData.kalanDk <= 1){
                                    rowStyle = 'red-row';
                                }
                                return rowStyle;
                                }}>
                                <Column field="id" header="Masa"/>
                                <Column field="tipId" header="Tip" body={(rowData) => getTipLabel(rowData.tipId)} />
                                <Column field="sinir" header="Sınır" body={(rowData) => emptyCheck(rowData, 'sinir')} />
                                <Column field="acilisTarihi" header="Açılış Saati" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'acilisTarihi')}</div>} />
                                <Column field="gecenDk" header="Geçen Dk" body={(rowData) => emptyCheck(rowData, 'gecenDk')} />
                                <Column field="kalanDk" header="Kalan Dk" body={(rowData) => emptyCheck(rowData, 'kalanDk')} />
                                <Column field="dahilEdilmeyenDk" header="Harici Dk" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'dahilEdilmeyenDk')}</div>} />
                            </DataTable>
                        )}
                    </TabPanel>
                    <TabPanel header="İstekler">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable virtualScrollerOptions={{ itemSize: 50 }} lazy rows={10} totalRecords={requests && requests.length ? requests.length : 0} value={requests} className={styles.dataTable} scrollable scrollHeight="630px" emptyMessage = 'Kayıt bulunamadı.'>
                        <Column field="id" header="İstek No" hidden={true}/>
                        <Column field="tipId" header="Tip" body={(rowData) => getTipLabel(rowData.tipId)} />
                        <Column field="sinir" header="Sınır" />
                        <Column field="aciklama" header="Açıklama" body={renderDescription} />
                        <Column field="istekTarihi" header="Tarih" body={(rowData) => formatDate(rowData.istekTarihi)} />
                        <Column field="onayDurum" header="Durum" body={(rowData) => statusIcons(rowData.onayDurum)} />
                        <Column
                            body={cancelButtonTemplate}
                        />
                    </DataTable>
                        )}
                    </TabPanel>
                </TabView>

                <Dialog header="Yeni İstek" visible={displayDialog} className="custom-dialog" onHide={() => setDisplayDialog(false)}>
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="tip" className="p-mr-2">Tip</label>
                            <Dropdown   
                                id="tip"
                                value={requestData.tipId}
                                options={masaTipleri}
                                onChange={(e) => setRequestData({ ...requestData, tipId: e.value })}
                                placeholder="Tip Seçin"
                                className="p-mb-3"
                            />
                            {errors.tipId && <small className="p-error">{errors.tipId}</small>}
                        </div>
                        <div className="p-field p-d-flex p-ai-center p-mb-4">
                            <label htmlFor="sinir" className="p-mr-2">Sınırlı mı?</label>

                            <InputNumber 
                                id="sinir"
                                value={0.00}
                                onValueChange={(e) => setRequestData({ ...requestData, sinir: e.target.value })}
                                minFractionDigits={2} 
                                maxFractionDigits={2} 
                                locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                                mode="currency" 
                                currency="TRY"
                                maxLength={8}
                            />
                        </div>
                        <div className="p-field p-mb-4">
                            <label htmlFor="aciklama">Açıklama</label>
                            <InputText
                                id="aciklama"
                                value={requestData.aciklama}
                                onChange={(e) => setRequestData({ ...requestData, aciklama: e.target.value })}
                                className="p-mb-3 custom-input-text"
                                maxLength={50}
                            />
                            {errors.aciklama && <small className="p-error">{errors.aciklama}</small>}
                        </div>
                        <div className="button-group">
                            <Button
                                label="Gönder"
                                icon="pi pi-check"
                                onClick={handleSendRequest}
                                className="custom-send-button"
                            />
                            <Button
                                label="Vazgeç"
                                icon="pi pi-times"
                                onClick={handleCancel}
                                className="p-button-secondary"
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default HomeUser;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import styles from './AdminUser.module.css';
import { Tag } from 'primereact/tag'; // PrimeReact Tag bileşeni durum ikonları için
import { format } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { ConfirmDialog, confirmDialog  } from 'primereact/confirmdialog';
import { FilterMatchMode} from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
        


const HomeAdmin = () => {
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [requests, setRequests] = useState([]);
    const [raporAltToplam, setRaporAltToplam] = useState([]);
    const [raporlar, setRaporlar] = useState([]);
    const [detaylar, setDetaylar] = useState([]);
    const [sinirTipButtonVisible, setSinirTipButtonVisible] = useState(false);
    const [sinirTip, setSinirTip] = useState('tl');
    const [secilenIstek, setSecilenIstek] = useState();
    const [kapamaVisible, setKapamaVisible] = useState(false);
    const [tasimaVisible, setTasimaVisible] = useState(true);
    const [isRaporDahilChecked, setIsRaporDahilChecked] = useState(true);
    const [tasimaButtonVisible, setTasimaButtonVisible] = useState(true);
    const [raporaEklenecekUcret, setRaporaEklenecekUcret] = useState();
    const [settings, setSettings] = useState([]);
    const [masaTipleri, setMasaTipleri] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [yeniButtonVisible, setYeniButtonVisible] = useState(false);
    const [silButtonVisible, setSilButtonVisible] = useState(false);
    const [kullanicilar, setKullanicilar] = useState();
    const [editingRows, setEditingRows] = useState({});
    const [secilenRapor, setSecilenRapor] = useState();
    const [detayVisible, setDetayVisible] = useState(false);
    const [istekOnayVisible, setIstekOnayVisible] = useState(false);
    const [secilenMasa, setSecilenMasa] = useState();
    const [masaKapatVisible, setMasaKapatVisible] = useState(false);
    const [yeniAyar, setYeniAyar] = useState({
        tanim: null,
        isMasaTipi: false,
        isRaporDahil: false,
        ucret: null, 
    });
    const [filters, setFilters] = useState({
        raporGun: { value: null, matchMode: FilterMatchMode.EQUALS },
        raporAy: { value: null, matchMode: FilterMatchMode.EQUALS },
        raporYil: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const dtDetaylarRef = useRef(null);
    const dtAyarlar = useRef(null);
    const dtRaporlarRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const user = location.state?.user;
    const confirmDialogRef = useRef(null);
    const masaIstekUyari = (istekId) => {
        confirmDialog({
            message: 'Bekleyen bir masa isteği var. Görmek ister misiniz?',
            header: 'Masa İsteği',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'Git',
            accept,
            acceptLabel: 'Git',
            rejectLabel: 'Sessize Al',
            reject: () => handleReject(istekId)
        });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchTables(false, true); // İlk yükleme değil, düzenli güncelleme için false geçiyoruz , refreshde setloading çalışmasın
            fetchRequests(false);
        }, 60000); // 60000 milisaniye = 1 dakika
        return () => clearInterval(intervalId);
    }, []);

    const accept = () => {
        setActiveIndex(1);
    }

    const handleReject = async (istekId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/istekSessizeAl`, { istekId });
            fetchRequests(false);
        } catch (error) {
            if (error.response) {
                showError(error.response.data);
            } else {
                showError('Sunucuya bağlanırken bir hata oluştu.');
            }
        }
    }

    const handleDropdownChange = (e) => {
        setSelectedDropdownValue(e.value);
    };
    
    const filterData = (data, filterSettings) => {
        return data.filter(row => {
            return Object.keys(filterSettings).every(key => {
                const filter = filterSettings[key];
                if (!filter || filter.value === null || filter.value === '') return true;

                const value = row[key]?.toString() || '';
                const filterValue = filter.value.toString();

                switch (filter.matchMode) {
                    case 'equals':
                        return value === filterValue;
                    case 'contains':
                        return value.toLowerCase().includes(filterValue.toLowerCase());
                    case 'startsWith':
                        return value.toLowerCase().startsWith(filterValue.toLowerCase());
                    case 'endsWith':
                        return value.toLowerCase().endsWith(filterValue.toLowerCase());
                    default:
                        return true;
                }
            });
        });
    };

    const onFilterChange = (e) => {
        setFilters(e.filters);
        const data = dtRaporlarRef.current.props.value;
        const filtered = filterData(data, e.filters);
        let toplam = 0.00
        filtered.forEach(element => {
            toplam += parseFloat(element.ucret) || 0;
        });
        setRaporAltToplam(toplam.toFixed(2))
    };

    const onFilterChangeManuel = (raporlarData) => {
        const today = new Date();
        const currentHour = today.getHours(); // Saat bilgisini al

        // Eğer saat 00:00 ile 06:00 arasındaysa bir önceki günü al
        if (currentHour >= 0 && currentHour <= 6) {
            today.setDate(today.getDate() - 1); // Bir önceki güne git
        }

        const currentDay = today.getDate(); // Gün bilgisini al
        const currentMonth = today.getMonth() + 1; // Aylar 0'dan başladığı için +1 ekle
        const currentYear = today.getFullYear(); // Yıl bilgisini al

        setFilters({
            raporGun: { value: currentDay, matchMode: FilterMatchMode.EQUALS },
            raporAy: { value: currentMonth, matchMode: FilterMatchMode.EQUALS },
            raporYil: { value: currentYear, matchMode: FilterMatchMode.EQUALS },
        });
        const data = raporlarData;
        const filtered = filterData(data, ({
            raporGun: { value: currentDay, matchMode: FilterMatchMode.EQUALS },
            raporAy: { value: currentMonth, matchMode: FilterMatchMode.EQUALS },
            raporYil: { value: currentYear, matchMode: FilterMatchMode.EQUALS },
        }));
        let toplam = 0.00
        filtered.forEach(element => {
            toplam += parseFloat(element.ucret) || 0;
        });
        setRaporAltToplam(toplam.toFixed(2))
    };



    const statusIcons = (status) => {
        switch (status) {
            case 0:
                return <Tag value="Beklemede" severity="warning" />;
            case 1:
                return <Tag value="Açık" severity="success" />;
            case 2:
                return <Tag value="Ret" severity="danger" />;
            case 3:
                return <Tag value="İptal" severity="danger" />;
            case 4:
                return <Tag value="Tamamlandı" severity="secondary" />;
            default:
                return '';
        }
    };


    const userStatusIcons = (status) => {
        switch (status) {
            case 0:
                return <Tag value="Beklemede" severity="warning" />;
            case 1:
                return <Tag value="Onaylı" severity="success" />;
            case 2:
                return <Tag value="Engelli" severity="danger" />;
            default:
                return '';
        }
    };

    const fetchTables = async (isFirstLoading, isRefresh) => {
        if (!isRefresh){
            setLoading(true);
        }
        fetchUsers();
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tables`);
            if (response && response.data){
                setTables(response.data);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };


    const fetchUsers = async (isFirstLoading) => {
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`); // API URL'sini güncelleyin
            if (response && response.data){
                setKullanicilar(response.data);
                const oturumdanAt = response.data.filter((item) => {return item.id === user.id && item.oturumId !== user.oturumId})
                if (oturumdanAt.length > 0){
                    showError('Başka bir oturum açıldı, bu oturum kapatılacaktır.')
                    setTimeout(() => {
                        handleLogout();
                }, 1500);
                }
            }
        } catch (error) {
        }
    };
    
    const fetchMasaTipleri = async (isFirstLoading) => {
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/masaTipleri`); // API URL'sini güncelleyin
            setMasaTipleri(response.data);
        } catch (error) {
        }
    };

    const fetchRequests = async (isFirstLoading) => {
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/istekler/${0}`);
            if (response && response.data){
                setRequests(response.data);
                response.data.forEach(element => {
                if(parseInt(element.isSessizMod) === 0){
                    masaIstekUyari(parseInt(element.id))
                }
            });
            }
        } catch (error) {
        }
    };

    const fetchSettings = async (isFirstLoading) => {
        if(isFirstLoading !== false){
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/ayarlar`);
            if (response && response.data){
                setSettings(response.data);
            }
        } catch (error) {
        }
    };

    const fetchRaporlar = async (isFirstLoading) => {
        if(isFirstLoading !== false){
        }
        try {
            let raporFiltrelendiMi = 0;
            if (raporlar.length > 0){
                raporFiltrelendiMi = 1
                onFilterChangeManuel(raporlar)
            }


            const response = await axios.get(`${process.env.REACT_APP_API_URL}/raporlar`);
            if (response && response.data){
                setRaporlar(response.data)
            }

            if(raporFiltrelendiMi === 0){
                onFilterChangeManuel(response.data)
            }
        } catch (error) {
        }
    };

    const fetchData = async (isFirstLoading) => {
        setLoading(true);
        try {
            await fetchSettings(isFirstLoading);
            await fetchUsers(isFirstLoading);
            await fetchMasaTipleri(isFirstLoading);
            await fetchRaporlar(isFirstLoading);
            await fetchRequests(isFirstLoading);
            await fetchTables(isFirstLoading);
        } catch (error) {
        }finally{
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy'); // Tarih formatlama
    };

    const formatDateWithHour = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() - 3); // 3 saat çıkar
        return format(date, 'dd.MM.yyyy HH:mm'); // Tarih formatlama
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleLogout = () => {
        navigate('/');
    };

    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: message });
    };



    const istekOnayRed = (rowData) => {
        if (rowData.onayDurum === 0) {
            return (
                <React.Fragment>
                <Button
                label="Onayla"
                className={`p-button-danger ${styles.approveButton}`}    
                    onClick={() => {
                        setIstekOnayVisible(true)
                        setSecilenIstek({id: rowData.id, aciklama: rowData.aciklama, tipId: rowData.tipId, sinir: rowData.sinir})
                    }}
                />
                <Button
                label="Reddet"
                className={`p-button-danger ${styles.cancelButton}`}    
                    onClick={() => handleRefuseRequest(rowData.id)}
                />
                </React.Fragment>
            );
        } else {
            return "";
        }
    };


    const kullaniciOnayEngel = (rowData) => {
        if (rowData.onayDurum === 0 && rowData.id !== user.id) {
            return (
                <React.Fragment>
                <Button
                label="Onayla"
                className={`p-button-danger ${styles.approveButton}`}    
                onClick={() => kullaniciOnayla(rowData.id)}
                />
                <Button
                label="Engelle"
                className={`p-button-danger ${styles.cancelButton}`}    
                    onClick={() => kullaniciEngelle(rowData.id)}
                />
                </React.Fragment>
            );
        }else if (rowData.onayDurum === 1 && rowData.id !== user.id){
            return (
                <React.Fragment>
                <Button
                label="Engelle"
                className={`p-button-danger ${styles.cancelButton}`}    
                    onClick={() => kullaniciEngelle(rowData.id)}
                />
                </React.Fragment>
            );
        }
        else if (rowData.onayDurum === 2 && rowData.id !== user.id) {
            return (
                <React.Fragment>
                <Button
                label="Onayla"
                className={`p-button-danger ${styles.approveButton}`}    
                onClick={() => kullaniciOnayla(rowData.id)}
                />
                </React.Fragment>
            )
        }else  {
            return "";
        }
    };

    const raporSil = async () => {
        if(!secilenRapor){
            showError("Rapor silmek için önce seçim yapılmalıdır.");
        }else{
             try {      
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/raporSil`, { id: secilenRapor.id});
            } catch (error) {
                showError(error.response.data);
            }   finally {
                fetchData(); // Verileri yeniden fetch et
            }
            setSecilenRapor();
        }
    };



    const handleCancel = () => {
        // Formu ve hata mesajlarını sıfırla
        setYeniAyar({
            tanim: null,
            isMasaTipi: '',
            isRaporDahil: '',
            ucret: '',
        });
        setErrors({});
        setDisplayDialog(false); // Popup'ı kapat
    };

    const handleRefuseRequest  = async (requestId) => {
        try {
            if (user && user.id) {
                await axios.post(`${process.env.REACT_APP_API_URL}/istekRet`, { istekId: requestId });
            } 
        } catch (error) {
            showError(error);
        } finally {
            fetchData();
        }
    };

    const kullaniciEngelle  = async (kullaniciId) => {
        try {
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_API_URL}/kullaniciEngelle`, { kullaniciId: kullaniciId });
        } catch (error) {
            showError(error);
        } finally {
            fetchData();
        }
    };

    const kullaniciOnayla  = async (kullaniciId) => {
        try {
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_API_URL}/kullaniciOnayla`, { kullaniciId: kullaniciId });
        } catch (error) {
            showError(error);
        } finally {
            fetchData();
        }
    };

    const yeniAyarKaydet  = async () => {
        let tempErrors = {};
        tempErrors.tanim = yeniAyar.tanim ? "" : "Tanım zorunludur.";
        tempErrors.ucret = yeniAyar.ucret > 0.00 ? "" : "Ücret zorunludur.";
        setErrors(tempErrors);
        if (Object.keys(tempErrors).every(key => !tempErrors[key])) {
            try {      
                setLoading(true)
                setDisplayDialog(false);
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/ayarGonder`, yeniAyar);
            } catch (error) {
                showError(error.response.data);
            } finally {
                fetchData(); // Verileri yeniden fetch et
                setYeniAyar({
                    tanim: null,
                    isMasaTipi: '',
                    isRaporDahil: '',
                    ucret: '',
                });
            }
        }
    };


    const renderDescription = (rowData) => {
        return (
            <div className={styles.ellipsis} title={rowData.aciklama}>
                {rowData.tipId || rowData.tipId !== 0 ? rowData.aciklama : ''}
            </div>
        );
    };

    const renderUserDescription = (rowData) => {
        return (
            <div className={styles.ellipsis} title={rowData}>
                {rowData}
            </div>
        );
    };

    const handleRequest = () => {
        setDisplayDialog(true); // Popup'ı göster
    };


    const rightContents = (
        <React.Fragment>
            <Button visible={yeniButtonVisible} label="Yeni" icon="pi pi-plus" onClick={handleRequest} className={`${styles.button} p-button-success p-button-outlined`} />
            <Button visible={silButtonVisible} label="Sil" icon="pi pi-trash" onClick={raporSil} className={`${styles.button} p-button-danger p-button-outlined`} />
            <Button 
                visible={tasimaButtonVisible} 
                label="Taşıma" 
                icon="pi pi-arrows-h" 
                onClick={() => setTasimaVisible(!tasimaVisible)} 
                className={`${styles.button} p-button-secondary p-button-outlined`} 
            />
            <Button 
                visible={sinirTipButtonVisible} 
                label= 'SınırTipi' 
                icon="pi pi-arrows-h" 
                onClick={() => {sinirTip === 'tl' ? setSecilenMasa({ ...secilenMasa, sinirDk: 0 }) : setSecilenMasa({ ...secilenMasa, sinir: 0 }); setSinirTip(sinirTip === 'dk' ? 'tl' : 'dk'); }} 
                className={`${styles.button} p-button-secondary p-button-outlined`} 
            />
            <Button icon="pi pi-refresh" onClick={fetchData} label="Yenile" className={`${styles.button} p-button-outlined`} />
            <Button label="Çıkış" icon="pi pi-sign-out" onClick={handleLogout} className={`${styles.button} p-button-danger p-button-outlined`} />
        </React.Fragment>
    );

    const getTipLabel = (tipId) => {
        const option = masaTipleri.find(opt => opt.id === tipId);
        return option ? option.tanim : '    ';
    };



    const getUserName = (kullaniciId) => {
        const option = kullanicilar.find(opt => opt.id === kullaniciId);
        return option ? renderUserDescription(option.kullaniciAdi + ' (' + option.aciklama + ')')  : '';
    };

    const emptyCheck = (rowData, field) => {
        if ((field === 'kalanDk' && parseInt(rowData.kalanDk) === 0) || (field === 'sinir' && parseFloat(rowData.sinir) === 0.00) || (field === 'dahilEdilmeyenDk' && parseFloat(rowData.dahilEdilmeyenDk) === 0.00)){
            return ''
        }
        if (field === 'gecenDk' && rowData.gecenDk < 0){
            return 0
        }

        if (field === 'sinirDk' && rowData.sinirDk <= 0){
            return ''
        }

        if (field === 'sinirDk') {
            return rowData.sinirDk + 'dk'; // sinirDk alanı için dakika ifadesi ekleniyor
        }

        if (field === 'acilisTarihi' && rowData.tipId !== 0){
            let acilisDate = new Date(rowData.acilisTarihi);
            acilisDate.setHours(acilisDate.getHours() - 3);
            acilisDate.setMinutes(acilisDate.getMinutes() + rowData.dahilEdilmeyenDk)
            const acilisTarihi = acilisDate ? acilisDate.toLocaleString('tr-TR', {
                hour: '2-digit',
                minute: '2-digit'
            }) : ''
            return acilisTarihi
        }
        return rowData.tipId || rowData.tipId !== 0 ? 
            (field === 'ucret' || field === 'sinir' ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(rowData[field]) 
                : rowData[field]) 
                    : '';
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(value);
      };

    const onRowEditComplete = async (e) => {
        setEditingRows((prevEditingRows) => {
            const newEditingRows = { ...prevEditingRows };
            delete newEditingRows[e.newData.id];
            return newEditingRows;
        });
        try {      
            setLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/ayarGuncelle`, {
                id: e.newData.id,
                tanim: e.newData.tanim,
                isMasaTipi: e.newData.isMasaTipi,
                isRaporDahil: e.newData.isRaporDahil,
                ucret: e.newData.ucret,
                minimumUcret: e.newData.minimumUcret,
            });
        } catch (error) {
            showError('Ayar güncellenirken hata alındı.');
        } finally {
            fetchData(); // Verileri yeniden fetch et
        }
        //update
    };

    const checkboxBodyTemplateMasaTipi = (rowData) => {
        if (rowData){
            return (
                <Checkbox 
                    checked={rowData.isMasaTipi === 1 || rowData.isMasaTipi === true ? true : false} 
                    disabled 
                />
            );
        }     
    };

    const checkboxBodyTemplateIsAdmin = (rowData) => {
        if (rowData){
            return (
                <Checkbox 
                    checked={rowData.isAdmin === 1 || rowData.isAdmin === true ? true : false} 
                    disabled 
                />
            );
        }     
    };

    const checkboxBodyTemplateRaporDahil = (rowData) => {
        if (rowData){
            return (
                <Checkbox 
                    checked={rowData.isRaporDahil === 1 || rowData.isRaporDahil === true ? true : false} 
                    disabled 
                />
            );
        }     
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  maxLength={50}/>;
    };

    const checkboxEditor = (options) => {
        return (
            <Checkbox 
            checked={options.value === 1 || options.value === true ? true : false} 
                onChange={(e) => options.editorCallback(e.checked)} 
            />
        );
    };  

    const numberEditor = (options) => {
        if (options.field === 'minimumUcret' && options.rowData.isMasaTipi === 0){
            return
        }
        return (
            <InputNumber 
                value={options.value} 
                onValueChange={(e) => options.editorCallback(e.value)} 
                minFractionDigits={2} 
                maxFractionDigits={2} 
                locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                mode="currency" 
                currency="TRY"
                maxLength={8}
            />
        );
    };  

    const masaDetayaYonlendir = async (secilenMasa, gelenIstekId) => {
        if (parseFloat(secilenMasa.sinir) > 0){
            setSinirTip('tl')
        }else if (secilenMasa.sinirDk > 0){
            setSinirTip('dk')
        }
        setSinirTipButtonVisible(true);
        setTasimaButtonVisible(false)
        setLoading(true);
        setSecilenMasa(secilenMasa);
        if (parseInt(secilenMasa.tipId) < 1 || gelenIstekId){
            setMasaKapatVisible(false);
        }else {
            setMasaKapatVisible(true);
        }
        try {      
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/masaDetayGetir/${secilenMasa.id}`);
            setDetaylar(response.data);
        } catch (error) {
            showError('Masa detayına yönlenirken hata alındı.');
        } finally {
            setActiveIndex(5);
            setDetayVisible(true); // Verileri yeniden fetch et
            setLoading(false);
        }
    };
    

    const confirmDelete = (rowData) => {
        confirmDialog({
            message: 'Bu ayarı silmek istediğinize emin misiniz?',
            header: 'Silme Onayı',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteRow(rowData.data),
            reject: () => {},
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
        });
    };


    const deleteRow = async (data) => {
        try {      
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/ayarSil`, { id: data.id , isMasaTipi : data.isMasaTipi });
            fetchData(); // Verileri yeniden fetch et
        } catch (error) {
            showError(error.response.data);
        }
    };


    const priceBodyTemplate = (rowData, field) => {
        if(field === 'ucret'){
            return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(rowData.ucret);
        }else if (field === 'minimumUcret' && rowData.isMasaTipi === 1){
            return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(rowData.minimumUcret); 
        }
    };


    const masaKapat = async () => {
        if (raporaEklenecekUcret === null && secilenMasa?.ucret === null){
            showError('Ücret boş olamaz!');
            return
        }
        setKapamaVisible(false)
        setLoading(true)
        secilenMasa.ucret = raporaEklenecekUcret ? raporaEklenecekUcret : secilenMasa.ucret;
        const masaId = secilenMasa.id;
        const acilisTarihi = secilenMasa.acilisTarihi;
        const istekId = secilenMasa.istekId;
        const ucret = raporaEklenecekUcret && raporaEklenecekUcret !== null && raporaEklenecekUcret !== '' ? raporaEklenecekUcret : secilenMasa.ucret;
        const tipId = secilenMasa.tipId;
        console.log(ucret)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/masaKapat`, {
                    masaId,
                    acilisTarihi,
                    ucret,
                    tipId,
                    isRaporDahilChecked,
                    istekId
            });
            if (response.status === 200) {
                fetchRaporlar(false);
                handleTabChange({index : 0})
                setIsRaporDahilChecked(true)
                setRaporaEklenecekUcret(null)
            } else {
                    showError(`Hata: ${response.data.message}`);
                }
            } catch (error) {
                showError(`Bir hata oluştu: ${error.response ? error.response.data.message : error.message}`);
            }
        
    };


    const handleTabChange = (e) => {
        setEditingRows({})
        setActiveIndex(e.index);
        const today = new Date();
        const currentHour = today.getHours(); // Saat bilgisini al

        // Eğer saat 00:00 ile 06:00 arasındaysa bir önceki günü al
        if (currentHour >= 0 && currentHour <= 6) {
            today.setDate(today.getDate() - 1); // Bir önceki güne git
        }

        const currentDay = today.getDate(); // Gün bilgisini al
        const currentMonth = today.getMonth() + 1; // Aylar 0'dan başladığı için +1 ekle
        const currentYear = today.getFullYear(); // Yıl bilgisini al

        setFilters({
            raporGun: { value: currentDay, matchMode: FilterMatchMode.EQUALS },
            raporAy: { value: currentMonth, matchMode: FilterMatchMode.EQUALS },
            raporYil: { value: currentYear, matchMode: FilterMatchMode.EQUALS },
        });
       if (e.index === 0){
        fetchTables(false, true);
        setYeniButtonVisible(false);
        setSilButtonVisible(false);
        setDetayVisible(false);
        setSecilenRapor();
        setSecilenMasa();
        setTasimaButtonVisible(true);
        setSinirTipButtonVisible(false);
       }else if (e.index === 1){
        fetchRequests(false);
        setYeniButtonVisible(false);
        setSilButtonVisible(false);
        setDetayVisible(false);
        setSecilenRapor();
        setTasimaButtonVisible(false);
        setSinirTipButtonVisible(false);
       }else if (e.index === 2){
        fetchSettings(false);
        setYeniButtonVisible(true);
        setSilButtonVisible(false);
        setDetayVisible(false);
        setSecilenRapor();
        setTasimaButtonVisible(false);
        setSinirTipButtonVisible(false);
       }else if (e.index === 3){
        fetchUsers(false);
        setYeniButtonVisible(false);
        setSilButtonVisible(false);
        setDetayVisible(false);
        setSecilenRapor();
        setTasimaButtonVisible(false);
        setSinirTipButtonVisible(false);
       }else if (e.index === 4){
        fetchRaporlar(false);
        setYeniButtonVisible(false);
        setSilButtonVisible(true);
        setDetayVisible(false);
        setSecilenRapor();
        setTasimaButtonVisible(false);
        setSinirTipButtonVisible(false);
       // onFilterChange({ filters: initialFilters });
       }
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            // Veriyi formatlayın
            const formattedData = raporlar.map(item => ({
                ...item,
                acilisTarihi: formatDate(item.acilisTarihi), // Tarih formatı uygulayın
                tipId: getTipLabel(item.tipId) // Tip etiketi uygulayın
            }));
    
            // Formatlanmış veriyi Excel'e aktarın
            const worksheet = xlsx.utils.json_to_sheet(formattedData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
    
            saveAsExcelFile(excelBuffer, 'raporlar');
        });
    };
    
    // Aynı saveAsExcelFile fonksiyonunu kullanabilirsiniz
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
    
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    
    const masaDegistir = async (masa) => {
        let birinciMasa;
        let ikinciMasa;
        for (let i = 0; i < masa.length; i++){
            if (tables[i].id !== masa[i].id){
                if(!birinciMasa){
                    birinciMasa = tables[i];
                }else {
                    ikinciMasa = tables[i];
                }
            }
        }
        if (!birinciMasa || !ikinciMasa){
            return
        }
        masaDegistirAPI(birinciMasa, ikinciMasa);
    };

    const masaDegistirAPI = async (birinciMasa, ikinciMasa) => {
        if (!birinciMasa || !ikinciMasa){
            showError('Masa seçimi zorunludur')
            return
        }
        setLoading(true);
        setPopupVisible(false); 
        setSelectedDropdownValue(null); 
        setSelectedRow(null);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/masaDegistir`, {
                    bId : birinciMasa.id,
                    bAciklama : birinciMasa.aciklama,
                    bAcilisTarihi : birinciMasa.acilisTarihi,
                    bDahilEdilmeyenDk : birinciMasa.dahilEdilmeyenDk,
                    bDetayUcret : birinciMasa.detayUcret,
                    bGecenDk : birinciMasa.gecenDk,
                    bIstekId : birinciMasa.istekId,
                    bKalanDk : birinciMasa.kalanDk,
                    bSinir : birinciMasa.sinir,
                    bTipId : birinciMasa.tipId,
                    bGuncellemeTarihi : birinciMasa.guncellemeTarihi,
                    bEskiMasaTipId: birinciMasa.eskiMasaTipId,
                    bEskiUcret: birinciMasa.eskiUcret,
                    iId : ikinciMasa.id,
                    iAciklama : ikinciMasa.aciklama,
                    iAcilisTarihi : ikinciMasa.acilisTarihi,
                    iDahilEdilmeyenDk : ikinciMasa.dahilEdilmeyenDk,
                    iDetayUcret : ikinciMasa.detayUcret,
                    iGecenDk : ikinciMasa.gecenDk,
                    iIstekId : ikinciMasa.istekId,
                    iKalanDk : ikinciMasa.kalanDk,
                    iSinir : ikinciMasa.sinir,
                    iTipId : ikinciMasa.tipId,
                    iGuncellemeTarihi : ikinciMasa.guncellemeTarihi,
                    ibEskiMasaTipId: ikinciMasa.eskiMasaTipId,
                    iEskiUcret: ikinciMasa.eskiUcret,

            });
            if (response.status === 200) {
                fetchTables(false, true);
            } else {
                    showError(`Hata: ${response.data.message}`);
                }
            } catch (error) {
                showError(`Bir hata oluştu: ${error.response ? error.response.data.message : error.message}`);
            }
    }
    

    const raporHeader = (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button 
                type="button" 
                icon="pi pi-file-excel" 
                severity="success" 
                rounded 
                onClick={exportExcel} 
                data-pr-tooltip="XLS" 
                style={{
                width: '2rem', 
                height: '2rem', 
                borderRadius: '50%', 
                padding: '0', 
                fontSize: '0.75rem', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center'
                }}
            />
        </div>
    );

    const formContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        marginBottom: '20px',
        fontSize: '0.9em'
    };

    const formRowStyle = {
        flex: '1 1 18%', // Her form öğesi %48 genişliğinde olacak
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.9em'
    };

    const inputStyle = {
        width: '100%',
        height: '100%'
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        gap: '40px',
        marginTop: '20px',
    };

    const handleRowMouseDown = (rowData) => {
        setSelectedRow(rowData);
        setTimeout(() => {
            setPopupVisible(true);
        }, 500); // 0.5 saniye sonra popup'ı gösterir
    };

    const onAdetChange = (e, rowIndex) => {
        const updatedData = detaylar.map((item, index) =>
            index === rowIndex ? { ...item, adet: e.value } : item
        );
        setDetaylar(updatedData);
    };

    const masaDetayKaydet = async () => {
        if (secilenMasa.tipId === 0) {
            showError("Masa tipi seçimi boş olamaz.");
            return;
        }
        setLoading(true)
        const acilisYil = new Date(secilenMasa.acilisTarihi).getFullYear();
        const masaTipiDegistiMi = tables.find((item) => {return item.id === secilenMasa.id && item.tipId !== secilenMasa.tipId && item.tipId !== 0})
        try {
            const masaGuncelleResponse = await axios.post(`${process.env.REACT_APP_API_URL}/masaGuncelle`, {
                tipId: secilenMasa.tipId,
                sinir: secilenMasa.sinir,
                aciklama: secilenMasa.aciklama,
                gecenDk: secilenMasa.gecenDk,
                kalanDk: secilenMasa.kalanDk,
                dahilEdilmeyenDk: secilenMasa.dahilEdilmeyenDk,
                sinirDk: secilenMasa.sinirDk,
                acilisTarihi: (acilisYil < 2024) ? undefined : secilenMasa.acilisTarihi,
                id: secilenMasa.id,
                istekId: secilenIstek && secilenIstek.id ? secilenIstek.id : 0,
                eskiMasaTipId: masaTipiDegistiMi ? masaTipiDegistiMi.tipId : 0,
            });
    
            if(secilenIstek && secilenIstek.id){
                await axios.post(`${process.env.REACT_APP_API_URL}/istekOnay`, { istekId: secilenIstek.id});
            }
    
            if (masaGuncelleResponse.status === 200) {
                // İlk olarak, mevcut masa detaylarını siliyoruz
                await axios.post(`${process.env.REACT_APP_API_URL}/masaDetaySil`, { id: secilenMasa.id });
    
                // Yeni masa detaylarını tek seferde ekliyoruz
                await axios.post(`${process.env.REACT_APP_API_URL}/masaDetayEkle`, {
                    detaylar: detaylar,  // Detaylar dizisini gönder
                    id: secilenMasa.id
                });
    
                // Verileri yeniden fetch ediyoruz
                handleTabChange({ index: 0 });
                setSecilenIstek();
            }
        } catch (error) {
            console.error("Masa güncelleme veya detay işlemleri sırasında hata oluştu:", error.message);
            showError("Masa güncelleme veya detay işlemleri sırasında hata oluştu:");
        }
    };
    

    return (
        <div className={styles.homeUser}>
            <Toast ref={toast} />
            <div className={styles.card}>
                <Toolbar end={rightContents} className="p-mb-4" />
                <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                    <TabPanel header="Masalar">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable 
                                value={tables} 
                                reorderableRows
                                onRowReorder={(e) => masaDegistir(e.value)}
                                className={styles.dataTable} 
                                emptyMessage = 'Kayıt bulunamadı.'
                                selectionMode="single" 
                                selection={secilenMasa} 
                                onSelectionChange={(e) => masaDetayaYonlendir(e.value)}
                                rowClassName={(rowData) => {
                                    const sinir = parseFloat(rowData.sinir)
                                    const sinirDk = rowData.sinirDk
                                    const gecenDk = rowData.gecenDk
                                    const ucret = parseFloat(rowData.ucret)
                                    const detayUcret = parseFloat(rowData.detayUcret)
                                    const finalUcret = ucret - detayUcret;
                                    let rowStyle = ''
                                    if (((sinir !== 0.00) && (finalUcret >= sinir) && rowData.kalanDk < 1) || (sinirDk > 0) && (gecenDk >= sinirDk) && rowData.kalanDk < 1){
                                        rowStyle = 'red-row';
                                    }
                                    return rowStyle;
                                }}>
                                <Column rowReorder style={{ width: '0.1rem' }} hidden = {tasimaVisible} />
                                <Column field="id" header="Masa" body={(rowData) => (
                                    <div 
                                        style={{ userSelect: 'none' }} 
                                        onMouseDown={() => handleRowMouseDown(rowData)}
                                        onTouchStart={() => handleRowMouseDown(rowData)}
                                    >
                                        {rowData.id}
                                    </div>
                                )}/>
                                <Column field="tipId" header="Tip" style={{ width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}  body={(rowData) => <div style={{ userSelect: 'none' }}>{getTipLabel(rowData.tipId)}</div>} />
                                <Column
                                    field="sinir"
                                    header="Sınır"
                                    body={(rowData) => (
                                    <div style={{ userSelect: 'none' }}>
                                        {parseFloat(rowData.sinir) > 0
                                        ? emptyCheck(rowData, 'sinir')
                                        : emptyCheck(rowData, 'sinirDk')}
                                    </div>
                                    )}
                                />
                                <Column field="acilisTarihi" header="Açılış Saati" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'acilisTarihi')}</div>} />
                                <Column field="gecenDk" header="Geçen Dk" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'gecenDk')}</div>} />
                                <Column field="kalanDk" header="Kalan Dk" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'kalanDk')}</div>} />
                                <Column field="dahilEdilmeyenDk" header="Harici Dk" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'dahilEdilmeyenDk')}</div>} />
                                <Column field="aciklama" header="Açıklama" body={(rowData) => <div style={{ userSelect: 'none' }}>{renderDescription(rowData)}</div>} />
                                <Column field="ucret" header="Ücret" body={(rowData) => <div style={{ userSelect: 'none' }}>{emptyCheck(rowData, 'ucret')}</div>} />
                            </DataTable>

                        )}

                <Dialog 
                    header={<div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>Masa Değiştir {selectedRow ? `(${selectedRow.id})` : ''}</div>}
                    visible={popupVisible} 
                    onHide={() => {setPopupVisible(false); setSelectedRow(null); setSelectedDropdownValue(null);}}
                    style={{ 
                        width: '250px', // Daha küçük boyut
                        padding: '1rem', // İçerik etrafında daha az boşluk
                        borderRadius: '8px', // Köşeleri yuvarlat
                        boxShadow: 'none' // Gölgeyi kaldır
                    }}
                    draggable={false}
                    resizable={false}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Dropdown 
                            value={selectedDropdownValue} 
                            options={tables.filter(table => selectedRow ? table.id !== selectedRow.id :'')}
                            optionLabel='id'
                            optionValue='id' 
                            onChange={handleDropdownChange} 
                            placeholder="Seçiniz"
                            style={{ width: '100%', marginBottom: '1rem', textAlign: 'left' }}
                        />
                        <Button 
                            label="Taşı" 
                            onClick={() => masaDegistirAPI(selectedRow, selectedDropdownValue)} 
                            style={{ width: '100%', padding: '0.5rem' }} 
                        />
                    </div>
                </Dialog>
                    </TabPanel>
                    <TabPanel header="İstekler">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable virtualScrollerOptions={{ itemSize: 50 }} lazy rows={10} totalRecords={requests && requests.length ? requests.length : 0} value={requests} className={styles.dataTable} scrollable scrollHeight="630px" emptyMessage = 'Kayıt bulunamadı.'>
                        <Column field="id" header="İstek No" hidden={true}/>
                        <Column field="kullaniciId" header="Kullanıcı" body={(rowData) => getUserName(rowData.kullaniciId)} />
                        <Column field="tipId" header="Tip" body={(rowData) => getTipLabel(rowData.tipId)} />
                        <Column field="sinir" header="Sınır" />
                        <Column field="aciklama" header="Açıklama" body={renderDescription} />
                        <Column field="istekTarihi" header="Tarih" body={(rowData) => formatDate(rowData.istekTarihi)} />
                        <Column field="onayDurum" header="Durum" body={(rowData) => statusIcons(rowData.onayDurum)} />
                        <Column
                            body={istekOnayRed}
                        />
                    </DataTable>
                    
                        )}
                        
                    </TabPanel>

                    <TabPanel header="Ayarlar">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable 
                            virtualScrollerOptions={{ itemSize: 50 }} lazy rows={10} totalRecords={settings && settings.length ? settings.length : 0}
                            value={settings} 
                            editMode="row" 
                            dataKey="id" 
                            emptyMessage = 'Kayıt bulunamadı.'
                            onRowEditComplete={onRowEditComplete} 
                            onRowEditCancel={confirmDelete}
                            editingRows={editingRows}
                            onRowEditChange={(e) => setEditingRows(e.data)}
                            className={styles.dataTable}
                            scrollable scrollHeight="630px"
                            ref={dtAyarlar}
                        >
                            <Column field="id" header="Ayar No" hidden = {true}/>
                            <Column field="tanim" header="Tanım" editor={(options) => textEditor(options)} />
                            <Column field="isMasaTipi" header="Masa Tipi?" body={checkboxBodyTemplateMasaTipi} editor={(options) => checkboxEditor(options)} />
                            <Column field="isRaporDahil" header="Rapora Dahil?" body={checkboxBodyTemplateRaporDahil} editor={(options) => checkboxEditor(options)} />
                            <Column field="minimumUcret" header="Min Ücret" body={(rowData) => priceBodyTemplate(rowData, 'minimumUcret')}  editor={(options) => numberEditor(options)} />
                            <Column field="ucret" header="Saatlik Ücret" body={(rowData) => priceBodyTemplate(rowData, 'ucret')}  editor={(options) => numberEditor(options)} />
                            <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

                        </DataTable>
                        )}
                        {/* Confirmation Dialog */}
            <ConfirmDialog/>
                    </TabPanel>
                    <TabPanel header="Üyeler">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable virtualScrollerOptions={{ itemSize: 50 }} lazy rows={10} totalRecords={kullanicilar && kullanicilar.length ? kullanicilar.length : 0} value={kullanicilar} className={styles.dataTable}  scrollable scrollHeight="630px" emptyMessage = 'Kayıt bulunamadı.'>
                                <Column field="id" header="Üye No" hidden = {true}/>
                                <Column field="kullaniciAdi" header="Kullanıcı" />
                                <Column field="aciklama" header="Açıklama" body={renderDescription}/>
                                <Column field="sifre" header="Şifre" hidden/>
                                <Column field="isAdmin" header="Admin?" body={checkboxBodyTemplateIsAdmin} editor={(options) => checkboxEditor(options)} />
                                <Column field="onayDurum" header="Durum" body={(rowData) => userStatusIcons(rowData.onayDurum)} />
                                <Column
                                body={kullaniciOnayEngel}
                                />
                            </DataTable>
                        )}
                    </TabPanel>
                    <TabPanel header="Raporlar">
                        {loading ? (
                            <div className={styles.loading}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable virtualScrollerOptions={{ itemSize: 50 }} rows={10} totalRecords={raporlar && raporlar.length ? raporlar.length : 0}  ref={dtRaporlarRef} value={raporlar} className={styles.dataTable}  scrollable scrollHeight="630px" filters={filters} filterDisplay="menu"
                            globalFilterFields={['raporGun', 'raporAy', 'raporYil']} emptyMessage = 'Kayıt bulunamadı.'
                            header={raporHeader} onFilter={ (e) => onFilterChange(e)} selectionMode="single" selection={secilenRapor} onSelectionChange={(e) => setSecilenRapor(e.value)}>
                                <Column field="id" header="Rapor No" hidden = {true}/>
                                <Column field="masaId"   header="Masa No" />
                                <Column field="tipId"  header="Tip" body={(rowData) => getTipLabel(rowData.tipId)} style={{ width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}/>
                                <Column field="acilisTarihi"  header="Açılış Tarihi"  body={(rowData) => formatDateWithHour(rowData.acilisTarihi)}/>
                                <Column field="kapanisTarihi"  header="Kapanış Tarihi" body={(rowData) => formatDateWithHour(rowData.kapanisTarihi)}/>
                                <Column field="raporGun" filter header="Rapor (Gün)" />
                                <Column field="raporAy" filter header="Rapor (Ay)"/>
                                <Column field="raporYil" filter header="Rapor (Yil)"/>
                                <Column field="aciklama" header="Açıklama" body={(rowData) => <div style={{ userSelect: 'none' }}>{renderDescription(rowData)}</div>} />
                                <Column 
                                    field="ucret" 
                                    header="Ücret" 
                                    body={(rowData) => formatCurrency(rowData.ucret)} 
                                    footer={`${formatCurrency(raporAltToplam)} ₺`} 
                                    style={{ width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                />
                            </DataTable>
                        )}
                    </TabPanel>
                    <TabPanel header="Detay" visible={detayVisible}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <div style={formContainerStyle}>
                        <div style={formRowStyle}>
                            <label htmlFor="masaTipi">Masa Tipi</label>
                            <Dropdown 
                                id="masaTipi"
                                options={masaTipleri}
                                value={masaTipleri.find(masaTipi => masaTipi.id === secilenMasa?.tipId) || null}
                                optionLabel='tanim'
                                optionValue='id'
                                onChange={(e) => setSecilenMasa({ ...secilenMasa, tipId: e.value.id })}
                                placeholder="Masa Tipi Seçin"
                                style={inputStyle}
                            />
                        </div>
                        <div style={formRowStyle}>
                            <label htmlFor="sinir">Sınır</label>
                            {sinirTip === 'tl' ? 
                            <InputNumber 
                            id="sinir"
                            style={inputStyle}
                            value={secilenMasa?.sinir}
                            minFractionDigits={2} 
                            maxFractionDigits={2} 
                            onChange={(e) => setSecilenMasa({ ...secilenMasa, sinir: e.value, sinirDk: 0 })}
                            locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                            mode="currency" 
                            currency="TRY"
                            maxLength={8}
                        /> : 
                        <InputNumber 
                        id="sinirDk"
                        style={inputStyle}
                        value={secilenMasa?.sinirDk ? secilenMasa?.sinirDk : 0}
                        onChange={(e) => setSecilenMasa({ ...secilenMasa, sinirDk: e.value, sinir: 0.00 })}
                        locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                        maxLength={3}
                        min={0}
                        />}
                            
                        </div>
                        <div style={formRowStyle}>
                            <label htmlFor="aciklama">Açıklama</label>
                            <InputText   
                                id="aciklama"
                                value={secilenMasa?.aciklama}
                                onChange={(e) => setSecilenMasa({ ...secilenMasa, aciklama: e.target.value })}
                                style={inputStyle}
                                maxLength={50}
                            />
                        </div>
                        <div style={formRowStyle}>
                            <label htmlFor="dahilEdilmeyenDk">Harici Dk</label>
                            <InputNumber 
                                id="dahilEdilmeyenDk"
                                style={inputStyle}
                                value={secilenMasa?.dahilEdilmeyenDk}
                                onChange={(e) => setSecilenMasa({ ...secilenMasa, dahilEdilmeyenDk: e.value })}
                                locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                                maxLength={2}
                            />
                        </div>
                    </div>

                    <DataTable ref={dtDetaylarRef} value={detaylar} scrollable scrollHeight="630px" emptyMessage = 'Kayıt bulunamadı.' className={styles.dataTable}>
                        <Column field="id" header="Ayar No" hidden={true} />
                        <Column field="tanim" header="Ürün" />
                        <Column
                            field="adet"
                            header="Adet"
                            body={(rowData, { rowIndex }) => (
                                <InputNumber 
                                id="dahilEdilmeyenDk"
                                value={rowData.adet}
                                onValueChange={(e) => onAdetChange(e, rowIndex)}
                                locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                                maxLength={2}
                                min={0}
                            />
                            )}
                        />
                    </DataTable>
                    <div style={buttonContainerStyle}>
                        <Button label="Kaydet" className="p-button-sm p-button-primary" onClick={() => masaDetayKaydet()}/>
                            <Button label="Kapat" className="p-button-sm p-button-danger" onClick={() => setKapamaVisible(true)} visible={masaKapatVisible}/>
                        <Button label="Vazgeç" className="p-button-sm p-button-secondary" onClick={() => handleTabChange({index : 0})}/>
                    </div>
                </>
            )}
        </TabPanel>
                </TabView>

                <Dialog header="Yeni Ayar" visible={displayDialog} className="custom-dialog" onHide={() => setDisplayDialog(false)}>
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="tanim" className="p-mr-2">Tanım</label>
                            <InputText   
                                id="tanim"
                                value={yeniAyar.tanim ? yeniAyar.tanim : ''}
                                onChange={(e) => setYeniAyar({ ...yeniAyar, tanim: e.target.value })}
                                className="p-mb-3"
                                maxLength={50}
                            />
                            {errors.tanim && <small className="p-error">{errors.tanim}</small>}
                        </div>
                        
                        <div className="p-field p-mb-4">
                            <label htmlFor="ucret">Ücret</label>

                            <InputNumber 
                                id="ucret"
                                value={0.00}
                                onChange={(e) => setYeniAyar({ ...yeniAyar, ucret: e.value })}
                                minFractionDigits={2} 
                                maxFractionDigits={2} 
                                locale="tr-TR" // Kendi locale'inize göre değiştirebilirsiniz
                                mode="currency" 
                                currency="TRY"
                                maxLength={8}
                            />
                            {errors.ucret && <small className="p-error">{errors.ucret}</small>}
                        </div>
                        <div className="p-field p-d-flex p-ai-center p-mb-4">
                            <label htmlFor="sinir" className="p-mr-2">Masa Tipi?</label>
                            <input
                                type="checkbox"
                                id="isMasaTipi"
                                checked={yeniAyar.isMasaTipi}
                                onChange={(e) => setYeniAyar({ ...yeniAyar, isMasaTipi: e.target.checked })}
                                className="p-mr-2"
                            />
                            
                        </div>
                        <div className="p-field p-d-flex p-ai-center p-mb-4">
                            <label htmlFor="isRaporDahil" className="p-mr-2">Rapor Dahil?</label>
                            <input
                                type="checkbox"
                                id="isRaporDahil"
                                checked={yeniAyar.isRaporDahil}
                                onChange={(e) => setYeniAyar({ ...yeniAyar, isRaporDahil: e.target.checked })}
                                className="p-mr-2"
                            />
                            
                        </div>
                        <div className="button-group">
                            <Button
                                label="Gönder"
                                icon="pi pi-check"
                                onClick={yeniAyarKaydet}
                                className="custom-send-button"
                            />
                            <Button
                                label="Vazgeç"
                                icon="pi pi-times"
                                onClick={handleCancel}
                                className="p-button-secondary"
                            />
                        </div>
                    </div>
                </Dialog>

                
                <Dialog header={<div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'left' }}>Masa Seçimi</div>}
                    onHide={(e) => setIstekOnayVisible(false)}
                    draggable={false}
                    resizable={false}
                    visible={istekOnayVisible} style={{ 
                        width: '200px', 
                        maxWidth: '90vw', 
                        textAlign: 'center',
                        borderRadius: '8px' 
                    }} >
                    <div style={{ width: '100%' }}>
                    <Dropdown 
                        options={tables.filter(table => table.tipId === 0)}
                        optionLabel='id'
                        optionValue='id'
                        onChange={(e) => {
                            e.value.tipId = secilenIstek.tipId;
                            e.value.aciklama = secilenIstek.aciklama;
                            e.value.sinir = secilenIstek.sinir;
                            setSecilenMasa(e.value);
                            masaDetayaYonlendir(e.value, secilenIstek.id);
                            setIstekOnayVisible(false);
                        }}
                        placeholder="Seçiniz"
                        style={{ width: '100%', marginTop: '0.5rem', textAlign: 'left'}} // Daha küçük genişlik ve üstten boşluk
                    />
                    </div>
                </Dialog>

           <Dialog 
    header={<div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>Masa Ücreti</div>}
    onHide={() => { setKapamaVisible(false); setRaporaEklenecekUcret(null); setIsRaporDahilChecked(true) }}
    draggable={false}
    resizable={false}
    visible={kapamaVisible}
    style={{ 
        width: '300px',  // Daha küçük bir genişlik belirleyin
        textAlign: 'center', 
        display: 'flex', 
        flexDirection: 'column', // Dikey hizalamayı sağlamak için eklendi
    }} 
>
    <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={{ marginBottom: '0.75rem' }}> {/* Margin boyutu küçültüldü */}
            <InputNumber 
                id="ucret"
                style={{ width: '100%', fontSize: '0.9rem' }}  // Stil küçültüldü
                value={raporaEklenecekUcret && raporaEklenecekUcret !== null && raporaEklenecekUcret != '' ? raporaEklenecekUcret : secilenMasa?.ucret}
                minFractionDigits={2} 
                maxFractionDigits={2} 
                onChange={(e) => setRaporaEklenecekUcret(e.value)}
                locale="tr-TR"
                mode="currency" 
                currency="TRY"
                maxLength={8}
            />
        </div>
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'flex-start', 
            marginBottom: '0.75rem'  // Margin boyutu küçültüldü
        }}>
            <Checkbox 
                inputId="checkbox" 
                checked={isRaporDahilChecked} 
                onChange={e => setIsRaporDahilChecked(e.checked)} 
            />
            <label htmlFor="checkbox" style={{ marginLeft: '0.5rem', fontSize: '0.9rem' }}>Rapora Dahil Et</label>  {/* Yazı boyutu küçültüldü */}
        </div>
        <Button 
            label="Kapat" 
            onClick={() => masaKapat()} 
            style={{ width: '100%', marginTop: '0.5rem', fontSize: '0.9rem', padding: '0.5rem' }}  // Buton boyutu küçültüldü
        />
    </div>
</Dialog>

            </div>
        </div>
    );
};

export default HomeAdmin;
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';


const Signup = ({ switchToLogin, toast }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // useNavigate hook'unu kullanın

    const validate = () => {
        let tempErrors = {};
        tempErrors.username = username.trim() ? "" : "Kullanıcı adı zorunludur.";
        tempErrors.password = password.trim() ? "" : "Şifre zorunludur.";
        tempErrors.description = description.trim() ? "" : "Açıklama zorunludur.";
        setErrors(tempErrors);
        return Object.keys(tempErrors).every(key => !tempErrors[key]);
    };

    const handleSignup = async () => {
        if (validate()) {
            try {
                setLoading(true)
                await axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
                    username: username.trim(),
                    password: password.trim(),
                    description: description.trim(),
                    isAdmin: false,
                    onayDurum: false
                });
                toast.current.show({severity: 'success', summary: 'Başarı', detail: 'Kayıt başarılı'});
                navigate('/')
            } catch (error) {
                console.log(error)
                toast.current.show({severity: 'error', summary: 'Hata', detail: error.response.data});
                setLoading(false)
            }
        }
    };
    
      // Inline styles for the profile icon container
      const profileIconStyle = {
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#007bff',
        width: '110px',
        height: '110px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        border: '4px solid white'
    };

    // Inline styles for the profile icon
    const profileIcon = {
        fontSize: '3rem',
        color: 'white'
    };

    return (
        <div className="login-container">
            <div className="login-card" style={{ position: 'relative', padding: '2rem', width: '100%', maxWidth: '400px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <ProgressSpinner />
                    </div>
                ) : (
                    <>
                        <div style={profileIconStyle}>
                            <i className="pi pi-user-plus" style={profileIcon}></i>
                        </div>
                        <h2 style={{ marginTop: '4rem', marginBottom: '2rem', color: '#343a40' }}>Kayıt Ol</h2>
                        <div className="p-field login-field" style={{ marginBottom: '1rem', textAlign: 'left' }}>
                            <label htmlFor="username">Kullanıcı Adı</label>
                            <InputText 
                                id="username" 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                style={{ width: '100%', borderRadius: '6px', border: '1px solid #ced4da', padding: '0.75rem', fontSize: '1rem' }} 
                            />
                            {errors.username && <small className="p-error">{errors.username}</small>}
                        </div>
                        <div className="p-field login-field" style={{ marginBottom: '1rem', textAlign: 'left' }}>
                            <label htmlFor="password">Şifre</label>
                            <InputText id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={20} style={{ width: '100%', borderRadius: '6px', border: '1px solid #ced4da', padding: '0.75rem', fontSize: '1rem' }} />
                            {errors.password && <small className="p-error" style={{ color: '#e74c3c', fontSize: '0.85em', marginTop: '0.5rem', display: 'block' }}>{errors.password}</small>}
                        </div>
                        <div className="p-field login-field" style={{ marginBottom: '1rem', textAlign: 'left' }}>
                            <label htmlFor="description">Açıklama</label>
                            <InputText 
                                id="description" 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)} 
                                placeholder="Onay sahibinin sizi tanıyabilmesi için..."
                                style={{ width: '100%', borderRadius: '6px', border: '1px solid #ced4da', padding: '0.75rem', fontSize: '1rem' }}
                            />
                            {errors.description && <small className="p-error">{errors.description}</small>}
                        </div>
                        <div className="button-group">
                            <Button label="Kayıt Ol" icon="pi pi-user-plus" onClick={handleSignup} style={{ width: '48%', padding: '0.75rem', fontSize: '1rem', borderRadius: '6px', transition: 'background-color 0.3s ease, box-shadow 0.3s ease' }}/>
                            <Button label="Giriş Yap" className="p-button-secondary" onClick={() => navigate('/')} style={{ width: '48%', padding: '0.75rem', fontSize: '1rem', borderRadius: '6px', transition: 'background-color 0.3s ease, box-shadow 0.3s ease' }}/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Signup;
